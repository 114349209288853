export type State = { classes: object };

import apiService from "@/core/services/apiService";
import {Module, VuexModule, Mutation, Action} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/store.enums";
import {SystemActions, SystemMutation} from "@/modules/system/store/enums";

@Module
export default class Store extends VuexModule {
    renderKey = 1;

    get getSystemRenderKey() {
        return 'system-' + this.renderKey;
    }

    @Mutation
    [SystemMutation.SET_RENDER_UPDATE]() {
        this.renderKey++;
    }

    @Action
    [SystemActions.GET_SETTINGS]() {

        return new Promise<void>((resolve, reject) => {

            apiService.get('system/settings').then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                // return results to dispatcher without mutation //
                resolve(data.result);

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [SystemActions.UPDATE_SETTINGS](payload) {

        return new Promise<void>((resolve, reject) => {

            const {data} = payload;

            apiService.put('system/patch', data).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

    @Action
    [SystemActions.OAUTH_AMAZON](payload) {

        return new Promise<void>((resolve, reject) => {

            apiService.post('licenses/oauth-amazon', payload).then(({data}) => {

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

}
