enum SystemActions {
    GET_SETTINGS = "systemFetchSettings",
    UPDATE_SETTINGS = "systemUpdateSettings",
    OAUTH_AMAZON = 'systemSetAmazonToken',
}

enum SystemMutation {
    SET_RENDER_UPDATE = "systemListRenderNeeded",
    SET_LIST_RESULT = "systemListResult"
}

export {SystemActions, SystemMutation};
